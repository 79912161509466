import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Facebook, Instagram } from "@mui/icons-material";

import SidePanel from "../Shared/SidePanel";
import logo from "../../assets/img/logo-dark.png";
import Footer from "../Shared/Footer";

export const WhatsappLogin = (props) => {
  const { search } = useLocation();

  const loginWithFacebook = async () => {
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get("token");
    let parsedToken = JSON.parse(token);

    window.launchWhatsAppSignup(
      parsedToken.projectId,
      decodeURIComponent(parsedToken.redirectUrl)
    );
    // console.log(StringifiedToken)
    // window.location.replace(`${'https://2021th.com'}/whatsapp/v1/waba_initialization?token=${StringifiedToken}`);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          sm={12}
          xs={12}
          sx={{ display: { xl: "none", md: "none", sm: "block", xs: "block" } }}
        >
          <LogoContainer>
            <img alt="Logo" src={logo} />
          </LogoContainer>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            height: { sm: "90vh", xs: "90vh", md: "100vh", xl: "100vh" },
          }}
          item
          xs={12}
          sm={12}
          md={6}
          xl={6}
        >
          <GridItem>
            <FacebookButton onClick={loginWithFacebook}>
              <Facebook />
              Continue with Facebook
            </FacebookButton>
          </GridItem>
          <Footer display={{ xs: "none", sm: "block" }} />
        </Grid>
        <Grid
          sx={{ display: { md: "block", sm: "none", xs: "none" } }}
          item
          xs={12}
          sm={12}
          md={6}
          xl={6}
        >
          <SidePanel hideFooter />
        </Grid>
      </Grid>
    </>
  );
};

const LogoContainer = styled("div")(() => {
  return {
    height: "10vh",
    padding: "20px",
  };
});

const GridItem = styled("div")(() => {
  return {
    display: "grid",
    placeItems: "center",
    flexGrow: 1,
  };
});

const FacebookButton = styled("button")(() => {
  return {
    display: "grid",
    gridTemplateColumns: "20px 1fr",
    alignItems: "center",
    gridGap: "10px",
    backgroundColor: "rgb(66, 103, 178)",
    borderColor: "rgb(66, 103, 178)",
    color: "#fff",
    lineHeight: "16px",
    fontSize: "14px",
    padding: "11px 24px",
    fontWeight: 700,
    borderRadius: "3px",
    borderStyle: "solid",
    borderWidth: "1px",
    textAlign: "center",
    transition: "all 0.35s ease-out",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    whiteSpace: "nowrap",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(54, 88, 153)",
      borderColor: "rgb(54, 88, 153)",
    },
  };
});

const InstagramButton = styled("button")(() => {
  return {
    display: "grid",
    gridTemplateColumns: "20px 1fr",
    alignItems: "center",
    gridGap: "10px",
    background: "#f09433",
    background: `-moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    )`,
    background: `-webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    )`,
    background: `linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    )`,
    border: "none",
    color: "#fff",
    lineHeight: "16px",
    fontSize: "14px",
    padding: "11px 24px",
    fontWeight: 700,
    textAlign: "center",
    transition: "all 0.35s ease-out",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    whiteSpace: "nowrap",

    "&:hover": {
      cursor: "pointer",
    },
  };
});
